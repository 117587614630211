<template>
  <v-row no-gutters>
    <v-col
      cols="12"
      sm="6"
      md="5"
      :class="{'pa-2': $vuetify.breakpoint.mdAndUp, 'show-info': viewInfo, 'hidden-info': !viewInfo, 'fixed-content': $vuetify.breakpoint.smAndDown}"
      :style="content_cliente"
    >
      <v-card
        flat
        class="fill-height"
      >
        <v-card-title class="blue-grey lighten-5 pl-0 d-flex justify-space-between">
          <div class="d-flex align-center">
            <v-btn 
              small 
              plain
              class="px-0 blue-grey--text"
              @click="toBack"
            >
              <v-icon size="18">mdi-arrow-left</v-icon>
            </v-btn>
            <span class="text-h5 primary--text font-weight-medium">Clientes</span>
          </div>
          <v-btn
            v-if="$vuetify.breakpoint.smAndDown"
            class="ml-3 mr-4"
            icon
            small
            @click="viewInfo = !viewInfo"
          >
            <v-icon>
              mdi-menu-open
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="px-0 mt-4">
          <v-row>           
            <v-col cols="12" class="px-6 pb-1"> 
              <search-expand 
                v-model="search" 
                placeholder="Buscar Cliente"
              />
            </v-col>
            <v-col cols="12" class="pt-0"> 
              <v-data-iterator
                :items="clients"
                :search="search"
                :footer-props="{itemsPerPageText: 'Mostrar'}"
                class="mb-0 pb-0"
              >
                <template v-slot:default="props">
                  <v-list
                    subheader
                    two-line
                    class="pt-0"
                  >
                    <template v-for="(cliente,i) in props.items"> 
                      <v-list-item
                        :key="'clientPotMap-'+i"
                        @click="centerCliente(cliente)"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            <v-icon left color="info">mdi-map-marker</v-icon>
                            <span
                              class="font-weight-medium"
                              v-text="`${cliente.nombre_comercial} (${cliente.co_cli_po})`"
                            />
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>        
                      <v-divider :key="'clientPotMaphr-'+i" />
                    </template>
                  </v-list>
                </template>
                <template #loading>
                  <v-skeleton-loader
                    type="list-item-two-line, list-item, list-item-two-line"
                  ></v-skeleton-loader>
                </template>
                <template v-slot:no-data>
                <div class="text-center pa-4">
                  <span class="blue-grey--text font-italic">No se encontraron Registros disponibles</span>
                </div>
                </template>
              </v-data-iterator>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      :md="viewInfo ? '7' : '12'"
      :style="height_mapa"
    >
      <view-maps 
        :load-data="false"
        :points="markers_clientes" 
        info 
        :centerable="center_cli" 
        :animation="center_cli" 
        :zoom="zoom_map"
      />
      <toggle-info v-model="viewInfo" />
    </v-col>
    <v-btn
      v-if="$vuetify.breakpoint.smAndDown"
      color="blue darken-2"
      fixed
      bottom
      left
      dark
      small
      style="bottom: 1rem !important"
      @click="toBack"
    >
      <v-icon size="18">mdi-arrow-left</v-icon>
      Regresar
    </v-btn>
  </v-row>
</template>
<script>
export default {
  name: 'Ubication',
  components: {
    ViewMaps: () => import(
      /* webpackChunkName: "view-maps" */
      '@/widgets/ViewMaps'
    ),
    ToggleInfo: () => import(
      /* webpackChunkName: "toggle-info" */
      '../Components/ToggleInfo.vue'
    ),
  },
  props: {
    clients: {
      type: Array,
      default: () => ([])
    },
  },
  data: () => ({
    search: '',
    sizeIcon: 'default',
    zoom_map: 7,
    center_cli:{
      lat: null,
      lng: null,
    },
    viewInfo: true,
  }),
  watch: {
    isMobile(val) {
      if (val) this.viewInfo = false
    },
  },
  created () {
    this.viewInfo = !this.$vuetify.breakpoint.smAndDown
  },
  methods: {
    toBack () {
      this.$emit('back')
    },
    centerCliente(cli) {
      if(cli.lat != null && cli.lng != null) {
        this.center_cli = {
          lat: parseFloat(cli.lat.trim()),
          lng: parseFloat(cli.lng.trim()),
        } 
        this.zoom_map = 15;
      }
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown
    },
    height_mapa() {
      return {
        height: this.$vuetify.breakpoint.mobile ? '95vh' : 'calc(100vh - 70px)',

      }
    },
    content_cliente () {
      return {
        'max-height': this.$vuetify.breakpoint.mobile ? '95vh' : 'calc(100vh - 70px)',
        height: this.$vuetify.breakpoint.mobile ? '95vh' : 'calc(100vh - 70px)',
        transition: 'all .5s',
        'overflow-y': 'auto',
        'overflow-x': 'hidden',
      }
    },
    markers_clientes () {
      return this.clients.length > 0 
        ? this.clients.filter(cli => cli != null && cli.lat != null)        
          .map(item => ({
            name: item.nombre_comercial,
            co_cli: item.co_cli_po,
            rif: item.rif,
            ven: item.ven_des,
            position: {
              lat: parseFloat(item.lat.trim()),
              lng: parseFloat(item.lng.trim()),
            },
            icon:{
              markerColor: '#00cae3',
              statusColor: '#00cae3',
            },
            animation: null
          })) 
        : []
    }
  }

}
</script>
<style>
.hidden-info {
  visibility: hidden;
  transform: translateX(-100%);
  position: fixed !important;
}
.show-info {
  transform: translateX(0);
}

.fixed-content {
  position: absolute !important;
  z-index: 201;
}
</style>